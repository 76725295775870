import React from 'react';
import Section from '../sections/Section';

const ContactFormMessageError = () => (
  <Section>
    <p>
      Sorry, deine Nachricht konnte nicht versand werden. Bitte versuche es
      später nochmal.
    </p>
  </Section>
);

export default ContactFormMessageError;
