import React from 'react';
import PropTypes from 'prop-types';
import Section from '../sections/Section';

import styles from './ContactForm.module.css';

const handleOnChange = changeAction => event => {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  changeAction(value);
};

const ContactForm = ({
  name,
  email,
  text,
  surename,
  category,
  onChangeName,
  onChangeEmail,
  onChangeText,
  onChangeSurename,
  onChangeCategory,
  validationIssues,
  submitDisabled,
  onSubmit,
}) => (
  <Section>
    <article>
      <form onSubmit={onSubmit} noValidate="novalidate">
        {validationIssues.length > 0 && (
          <div className="">
            <div className={styles.validationIssues}>
              Bitte überprüfe nochmal deine Eingaben auf folgende Fehler:
              <ul className={styles.validationIssues__Listing}>
                {validationIssues.map(issue => (
                  <li key={issue}>{issue}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <div className={styles.field}>
          <label>Betreff</label>
          <select
            onChange={handleOnChange(onChangeCategory)}
            name="category"
            value={category}
          >
            <option value="Allgemein">Allgemein</option>
            <option value="Probetraining-Damen">Probetraining - Damen</option>
            <option value="Probetraining-Herren">Probetraining - Herren</option>
            <option value="Vorstand">Fragen an den Vorstand</option>
            <option value="Webseite">Fragen zur Webseite</option>
          </select>
        </div>
        <div className={styles.field}>
          <label>Name</label>
          <div className={styles.nameInputWrapper}>
            <input
              onChange={handleOnChange(onChangeSurename)}
              name="surename"
              className={styles.surename}
              required
              type="text"
              placeholder="don't fill this out!"
              value={surename}
            />
            <input
              onChange={handleOnChange(onChangeName)}
              name="name"
              className={styles.name}
              type="text"
              value={name}
            />
          </div>
        </div>
        <div className={styles.field}>
          <label>Email</label>
          <input
            onChange={handleOnChange(onChangeEmail)}
            name="email"
            type="email"
            value={email}
          />
        </div>
        <div className={styles.field}>
          <label>Nachricht</label>
          <textarea
            onChange={handleOnChange(onChangeText)}
            name="text"
            className={styles.textarea}
            placeholder="Hier kannst du deine Nachricht eingeben"
            value={text}
          />
        </div>
        <div className={styles.formSubmit}>
          <button disabled={submitDisabled} type="submit">
            Senden
          </button>
        </div>
      </form>
    </article>
  </Section>
);

ContactForm.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  text: PropTypes.string,
  surename: PropTypes.string,
  category: PropTypes.string,
  onChangeName: PropTypes.func,
  onChangeEmail: PropTypes.func,
  onChangeText: PropTypes.func,
  onChangeSurename: PropTypes.func,
  onChangeCategory: PropTypes.func,
  validationIssues: PropTypes.array,
  submitDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default ContactForm;
