/* global URLSearchParams, fetch, location */
import React, { Component } from 'react';

import config from '../config';

import Header from '../components/Header';
import Layout from '../components/Layout';

import {
  ContactForm,
  ContactFormMessageError,
  ContactFormMessageSend,
} from '../components/ContactForm';

const SEND_STATUS = {
  NOTHING: 'nothing',
  SENDING: 'sending',
  SUCCESS: 'successful',
  ERROR: 'error',
};

export default class Contact extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      validationIssues: [],
      name: '',
      email: '',
      text: '',
      surename: '',
      category: 'Allgemein',
      sendStatus: SEND_STATUS.NOTHING,
    };
    this.disabled = false;
    const setStateOnChange = stateName => val => {
      this.setState({ [`${stateName}`]: val });
    };
    this.onChangeName = setStateOnChange('name');
    this.onChangeSurename = setStateOnChange('surename');
    this.onChangeEmail = setStateOnChange('email');
    this.onChangeText = setStateOnChange('text');
    this.onChangeCategory = setStateOnChange('category');

    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderBySendStatus = this.renderBySendStatus.bind(this);
  }

  componentDidMount() {
    if (
      typeof URLSearchParams !== 'undefined' &&
      typeof location !== 'undefined'
    ) {
      const params = new URLSearchParams(location.search);
      const category = params.get('kategorie');
      if (category) {
        this.setState({ category });
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { name, email, text, category, surename, sendStatus } = this.state;

    if (sendStatus !== SEND_STATUS.SENDING) {
      this.setState({ sendStatus: SEND_STATUS.SENDING });

      fetch(`${config.contactService}`, {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({
          name,
          surename,
          text,
          email,
          category,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => console.log(response.body) || response.json())
        .then(res => console.log(res) || res)
        .then(({ validationIssues = [] }) => {
          this.setState({
            validationIssues,
            sendStatus: SEND_STATUS.SUCCESS,
          });
        })
        .catch(e => {
          console.log('catch', e);
          this.setState({ sendStatus: SEND_STATUS.ERROR });
        });
    }
  }

  renderBySendStatus() {
    const { sendStatus, ...formProps } = this.state;
    switch (sendStatus) {
      case SEND_STATUS.SUCCESS:
        return !formProps.validationIssues ||
          formProps.validationIssues.length === 0 ? (
          <ContactFormMessageSend />
        ) : (
          <ContactForm
            {...formProps}
            onChangeCategory={this.onChangeCategory}
            onChangeName={this.onChangeName}
            onChangeSurename={this.onChangeSurename}
            onChangeText={this.onChangeText}
            onChangeEmail={this.onChangeEmail}
            onSubmit={this.handleSubmit}
          />
        );
      case SEND_STATUS.ERROR:
        return <ContactFormMessageError />;
      default:
        return (
          <ContactForm
            {...this.state}
            onChangeCategory={this.onChangeCategory}
            onChangeName={this.onChangeName}
            onChangeSurename={this.onChangeSurename}
            onChangeText={this.onChangeText}
            onChangeEmail={this.onChangeEmail}
            disabled={sendStatus === SEND_STATUS.SENDING}
            onSubmit={this.handleSubmit}
          />
        );
    }
  }

  render() {
    return (
      <Layout>
        <Header title={'Willkommen beim VC-TK'} />
        {this.renderBySendStatus()}
      </Layout>
    );
  }
}
